<template>
	<div class="template-wrapper template-wrapper--desktop">
		<div v-if="!isEmbedded" class="brand-logo-wrapper">
			<div v-if="!hideBackground" class="line-wrapper" />
			<div v-if="!hideBackground" class="layer-blur" />
			<AppLogo />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLogo from '@/shared/app-logo/AppLogo';

export default {
	name: 'BrandLogo',
	components: {
		AppLogo,
	},
	props: {
		hideBackground: Boolean,
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
};
</script>
<style lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.template-wrapper--desktop {
	display: none;

	@include min-screen($md + 1) {
		display: block;
	}
}

.line-wrapper {
	background-image: url('../../assets/lines.svg');
	background-repeat: no-repeat, repeat;
}
</style>
